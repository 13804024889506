import { Question  } from '../models/question';

/**
 * Bot service
 *
 * Overview: Provides Bot related services
 *
 */

export class BotService {
    constructor() {
    }

    /**
     * Calls bot API to ask question.
     * @param botKnowledgeBaseId API knowledgeBase Id
     * @param botAuthorizationEndpointKey API authorization key
     * @param question Model housing bot question API model
     * @returns Status 200 if returned
     */
    // @ts-ignore
    askQuestion(endpointRoot: string, botKnowledgeBaseId: string, botAuthorizationEndpointKey: string, question: Question) {
        // console.log("botAuthorizationEndpointKey: ", botAuthorizationEndpointKey);
        // let gameId = endpointRoot.split("-")[0];

        return new Promise((resolve, reject) => {
            // fetch('https://api.huntakiller.com/qna/'+gameId, {
            console.log("botKnowledgeBaseId: ", botKnowledgeBaseId);
            fetch('https://hak-qna-maker.azurewebsites.net/qnamaker/knowledgebases/' + botKnowledgeBaseId + '/generateAnswer', {
                method: 'POST',
                body: JSON.stringify(question),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': botAuthorizationEndpointKey
                }
            }).then((response) => {
                if (response.ok) {
                    return resolve(response);
                } else {
                    return reject(response);
                }
            }).catch(e => {
                console.log(e)
            });;
        });
    }
}
